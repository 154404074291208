import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";
import useQueryParams from "query-params-hook";
import { StaticImage } from "gatsby-plugin-image";
import { isTablet, isMobile } from "react-device-detect";

import "../styles/princess.css";

interface FormType {
  full_name: string;
  email: string;
  player_id: string;
  address: string;
  city: string;
  state: string;
  postal_code: string;
  phone?: string;
}

const DEFUALT_VALUES = {
  full_name: "",
  email: "",
  player_id: "",
  address: "",
  city: "",
  state: "",
  postal_code: "",
  phone: "",
};
const Princess = () => {
  const { playerId } = useQueryParams();
  const [isLandscape, setIslandscape] = useState(false);
  const [checkboxState, setCheckboxState] = useState({
    checkbox0: false,
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [result, setResult] = useState("");
  const [formData, setFormData] = useState<FormType>({
    ...DEFUALT_VALUES,
    player_id: /^\d+$/.test(playerId) ? playerId : "",
  });

  const [errors, setErrors] = useState<Partial<FormType>>({});

  const [isPopupVisible, setPopupVisible] = useState(false);
  const togglePopup = () => setPopupVisible(!isPopupVisible);

  useEffect(() => {
    setIslandscape(isMobile && !isTablet);
  }, [isMobile, isTablet]);

  const stateList = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const validateForm = (name: keyof FormType, value: string) => {
    switch (name) {
      case "full_name": {
        if (!value) {
          errors.full_name = "Full Name is required.";
        } else if (
          !/^[A-Za-z\s]+$/.test(value.trim()) ||
          value.trim().split(" ").length < 2
        ) {
          errors.full_name =
            "Full Name must contain only letters and at least 2 words.";
        } else {
          errors.full_name = "";
        }
        break;
      }
      case "email": {
        if (!value) {
          errors.email = "Email Address is required.";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim())) {
          errors.email = "Please enter a valid email address.";
        } else {
          errors.email = "";
        }
        break;
      }
      case "player_id": {
        if (!/^\d+$/.test(value.trim())) {
          errors.player_id = "Player ID must be numbers.";
        } else if (!value) {
          errors.player_id = "Quick Hit Player ID is required.";
        } else {
          errors.player_id = "";
        }
        break;
      }
      case "address": {
        if (!value) {
          errors.address = "United States Residential Address is required.";
        } else {
          errors.address = "";
        }
        break;
      }
      case "city": {
        if (!value) {
          errors.city = "City/Town is required.";
        } else {
          errors.city = "";
        }
        break;
      }
      case "state": {
        if (!value) {
          errors.state = "State/Province is required.";
        } else {
          errors.state = "";
        }
        break;
      }
      case "postal_code": {
        if (!value) {
          errors.postal_code = "ZIP/Postal Code is required.";
        } else if (!/^\d+$/.test(value.trim())) {
          errors.postal_code = "ZIP/Postal Code must contain numbers only.";
        } else {
          errors.postal_code = "";
        }
        break;
      }
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const onChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;

    setFormData(() => ({
      ...formData,
      [name]: value,
    }));
    validateForm(name, value);
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [id]: e.target.checked,
    }));
  };

  const checkedKeys = useMemo(() => {
    const keys = { ...errors };
    if (!formData.phone) {
      delete keys.phone;
    }
    return keys;
  }, [formData]);

  const isValid = useMemo(() => {
    const temp = { ...formData };
    if (!formData.phone) {
      delete temp.phone;
    }
    return (
      !Object.values(checkedKeys).some((item) => item) &&
      Object.values(temp).every((item) => item) &&
      Object.values(checkboxState).every((checked) => checked)
    );
  }, [checkedKeys, formData, checkboxState]);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!Object.values(checkedKeys).some((item) => item)) {
      setLoading(true);
      setResult("");
      const response = await fetch(
        "https://du4clmz2x1.execute-api.us-east-2.amazonaws.com/add-princess",
        {
          method: "POST",
          body: JSON.stringify({
            ...formData,
            phone: formData.phone?.replace(/\D/g, ""),
          }),
        }
      );
      if (response.status === 200) {
        setSuccess(true);
        setFormData({
          ...DEFUALT_VALUES,
          player_id: playerId || "",
        });
        setErrors({});
      } else {
        setResult("There is something wrong.");
      }
      setLoading(false);
    }
  };

  const formatPhoneNumber = (value) => {
    // Remove all non-numeric characters
    const numbers = value.replace(/\D/g, "");

    // Format as 123-456-7890
    const formattedPhone =
      numbers.length <= 3
        ? numbers
        : numbers.length <= 6
        ? `${numbers.slice(0, 3)}-${numbers.slice(3)}`
        : `${numbers.slice(0, 3)}-${numbers.slice(3, 6)}-${numbers.slice(
            6,
            10
          )}`;

    return formattedPhone;
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const formatted = formatPhoneNumber(value);
    const numericValue = value.replace(/-/g, "");

    if (numericValue && !/^\d+$/.test(numericValue.trim())) {
      errors.phone = "Phone Number must contain numbers only.";
    } else {
      errors.phone = "";
    }

    setFormData((prev) => ({ ...prev, phone: formatted }));
  };

  const removeFirstSpace = (e: any) => {
    const { value } = e.target;
    if (e.key === " " && !value) {
      e.preventDefault();
    }
  };

  const validateEnglishInput = (e: any) => {
    const { value } = e.target;
    const regex = /[A-Za-z ]/g;

    if (!regex.test(e.key) || (e.key === " " && !value)) {
      e.preventDefault();
    }
  };

  const acceptNumerOnly = (e: any) => {
    removeFirstSpace(e);
    const regex = /\D/g;
    if (regex.test(e.key) && e.key != "Backspace") {
      e.preventDefault();
    }
  };

  const customValidation = (e: any) => {
    removeFirstSpace(e);
    const regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~ ]$/;

    if (!regex.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  return (
    <>
      <div
        className={`landscape-warning ${isLandscape ? "mobile-landscape" : ""}`}
      >
        <noscript>
          {`
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-5XWQG4H3"
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      />`}
        </noscript>
        <StaticImage
          className="ml-4"
          src="../images/princess/rotate.png"
          alt="background"
          width={150}
        />
        <p>To register, rotate your device to portrait mode.</p>
      </div>
      <div
        className={`page-content flex flex-col md:flex-row bg-white h-[100dvh] ${
          isLandscape ? "mobile-landscape" : ""
        }`}
      >
        <div className="min-h-[350px] md:w-[50%] relative overflow-hidden">
          <StaticImage
            className={"h-[100%] landscape:!fixed landscape:w-[50%]"}
            src="../images/princess/bg.png"
            alt="background"
          />
          <div className="w-[100%] landscape:w-[50%] absolute landscape:!fixed top-[5%] md:top-[10%] text-center">
            <StaticImage
              className="w-[60%]"
              src="../images/princess/logo.png"
              alt="logo"
            />
          </div>
          <h1 className="w-[100%] landscape:w-[50%] absolute landscape:!fixed bottom-[10px] z-10 text-white text-[3.5rem] xl:text-[6rem] 2xl:text-[8rem] text-center leading-none tracking-[0.03em] font-black">
            PRINCESS RAFFLE <br />
            REGISTRATION
          </h1>
        </div>
        <div className="md:w-[50%] relative bg-white landscape:min-h-full h-fit">
          {!success ? (
            <>
              <div className="princess-title relative p-6 text-white text-[18px] md:text-[2rem] 2xl:text-[4rem] text-center leading-none">
                Sign up for a chance <br />
                to win a Princess Cruise!
                <StaticImage
                  className={`!absolute top-[25px] right-[0px] md:right-[10px] w-[90px] lg:w-[170px] landscape:w-[90px] ${
                    isTablet ? "!w-[70px] !right-0" : ""
                  }`}
                  src="../images/princess/gift.png"
                  alt="logo"
                />
              </div>
              <p className="text-[18px] md:text-[2rem] 2xl:text-[4rem] text-center mt-10 md:mt-4 2xl:mt-10 leading-none">
                <span className="font-bold">To qualify & win a gift,</span>{" "}
                <br />
                <span className="font-light">
                  complete requested information in full
                </span>
              </p>
              <form
                onSubmit={onSubmit}
                className="princess-form p-10 m-8 md:m-4 2xl:m-10 rounded-[14px] items-center"
              >
                <h2 className="w-full text-white text-[2rem] 2xl:text-[4rem] text-center font-bold leading-none tracking-[0.03em] mb-8">
                  Register Here
                </h2>
                <div className="flex flex-col gap-4">
                  <input
                    type="text"
                    name="full_name"
                    className="w-full rounded-[8px] p-4 md:text-[10px] 2xl:text-[16px]"
                    placeholder="Full Name *"
                    value={formData.full_name}
                    onChange={onChange}
                    disabled={loading}
                    required
                    onKeyDown={validateEnglishInput}
                  />
                  {errors.full_name && (
                    <p className="text-red-500 text-sm">{errors.full_name}</p>
                  )}
                  <input
                    type="text"
                    name="email"
                    className="w-full rounded-[8px] p-4 md:text-[10px] 2xl:text-[16px]"
                    placeholder="Email Address *"
                    value={formData.email}
                    onChange={onChange}
                    disabled={loading}
                    required
                    onKeyDown={removeFirstSpace}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm">{errors.email}</p>
                  )}
                  <div className="relative">
                    <input
                      type="text"
                      name="player_id"
                      className="w-full rounded-[8px] p-4 md:text-[10px] 2xl:text-[16px]"
                      placeholder="Quick Hit Player ID *"
                      value={formData.player_id}
                      onChange={onChange}
                      disabled={loading}
                      required
                      onKeyDown={acceptNumerOnly}
                    />
                    <div onClick={togglePopup}>
                      <StaticImage
                        data-tooltip-id="tooltip-playerid"
                        className="cursor-pointer !absolute right-[10px] top-[6px]"
                        src="../images/princess/info.png"
                        alt="submit-btn"
                        width={20}
                      />
                    </div>
                    {errors.player_id && (
                      <p className="text-red-500 text-sm mt-4">
                        {errors.player_id}
                      </p>
                    )}
                  </div>
                  <input
                    type="text"
                    name="address"
                    className="w-full rounded-[8px] p-4 md:text-[10px] 2xl:text-[16px]"
                    placeholder="United States Residential Address *"
                    value={formData.address}
                    onChange={onChange}
                    disabled={loading}
                    required
                    onKeyDown={customValidation}
                  />
                  {errors.address && (
                    <p className="text-red-500 text-sm">{errors.address}</p>
                  )}
                  <input
                    type="text"
                    name="city"
                    className="w-full rounded-[8px] p-4 md:text-[10px] 2xl:text-[16px]"
                    placeholder="City/Town *"
                    value={formData.city}
                    onChange={onChange}
                    disabled={loading}
                    required
                    onKeyDown={customValidation}
                  />
                  {errors.city && (
                    <p className="text-red-500 text-sm">{errors.city}</p>
                  )}
                  <div className="relative">
                    <select
                      name="state"
                      className={`w-full rounded-[8px] p-4 md:text-[10px] 2xl:text-[16px] appearance-none ${
                        !formData.state ? "text-gray-500" : ""
                      }`}
                      value={formData.state}
                      onChange={onChange}
                      disabled={loading}
                      required
                    >
                      <option value="">Select State/Province *</option>
                      {stateList.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none w-[20px]">
                      <svg
                        fill="#000000"
                        height="800px"
                        width="800px"
                        version="1.1"
                        id="Layer_1"
                        viewBox="0 0 330 330"
                      >
                        <path
                          id="XMLID_225_"
                          d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                        />
                      </svg>
                    </span>
                  </div>
                  {errors.state ||
                    (!!formData.postal_code && !formData.state && (
                      <p className="text-red-500 text-sm">
                        {errors.state || "State/Province is required."}
                      </p>
                    ))}
                  <input
                    type="text"
                    name="postal_code"
                    className="w-full rounded-[8px] p-4 md:text-[10px] 2xl:text-[16px]"
                    placeholder="ZIP/Postal Code *"
                    value={formData.postal_code}
                    onChange={onChange}
                    disabled={loading}
                    required
                    onKeyDown={acceptNumerOnly}
                  />
                  {errors.postal_code && (
                    <p className="text-red-500 text-sm">{errors.postal_code}</p>
                  )}
                  <input
                    type="text"
                    name="phone"
                    className="w-full rounded-[8px] p-4 md:text-[10px] 2xl:text-[16px]"
                    placeholder="Phone Number (Optional)"
                    value={formData.phone}
                    disabled={loading}
                    onChange={handlePhoneChange}
                  />
                  {errors.phone && (
                    <p className="text-red-500 text-sm">{errors.phone}</p>
                  )}
                  <label className="checkbox 2xl:mt-2">
                    <input
                      type="checkbox"
                      onChange={(e) => handleCheckboxChange(e, "checkbox0")}
                      disabled={loading}
                      required
                    />
                    I confirm that I am 21 or older *
                  </label>
                  <label className="checkbox 2xl:mt-2">
                    <input
                      type="checkbox"
                      onChange={(e) => handleCheckboxChange(e, "checkbox1")}
                      disabled={loading}
                      required
                    />
                    I am a United States resident *
                  </label>
                  <label className="checkbox 2xl:mt-2">
                    <input
                      type="checkbox"
                      onChange={(e) => handleCheckboxChange(e, "checkbox2")}
                      disabled={loading}
                      required
                    />
                    I consent to Quick Hit Slots/SciPlay sharing all the above
                    information with Carnival Corporation *
                  </label>
                  <label className="checkbox 2xl:mt-2">
                    <input
                      type="checkbox"
                      onChange={(e) => handleCheckboxChange(e, "checkbox3")}
                      disabled={loading}
                      required
                    />
                    I agree to receive commercial/marketing materials *
                  </label>{" "}
                </div>
                {loading ? (
                  <div className="form-btn pt-4 text-center text-white font-bold text-[16px] justify-center items-center flex dark:invert gap-2">
                    <div className="h-8 w-8 bg-white rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-8 w-8 bg-white rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-8 w-8 bg-white rounded-full animate-bounce"></div>
                  </div>
                ) : (
                  <button className="form-btn" disabled={!isValid}>
                    <StaticImage
                      src="../images/princess/submit.png"
                      alt="submit-btn"
                      width={200}
                    />
                  </button>
                )}
              </form>
            </>
          ) : (
            <div className="success-message relative m-12 rounded-[14px] p-10 md:p-[20px] text-center w-auto">
              <p className="text-white text-[20px] lg:text-[4rem] 2xl:text-[6rem]">
                You are in
                <br />
                the raffle!
              </p>
              <p className="text-white text-[24px] lg:text-[6rem] 2xl:text-[8rem] font-bold">
                GOOD LUCK!
              </p>
              <p className="text-white text-[16px] lg:text-[4rem] 2xl:text-[5rem] mb-5">
                Here's a gift <br />
                to keep <br />
                you spinning!
              </p>
              {!!result && <p className="text-red-500 text-sm">{result}</p>}
              <button
                className="success-btn mb-10 lg:mb-0"
                onClick={() =>
                  navigate("https://quickhitslots.jckpt.me/e0007c0919")
                }
              >
                <StaticImage
                  src="../images/princess/collect.png"
                  alt="submit-btn"
                  width={200}
                />
              </button>
              <StaticImage
                className="!absolute bottom-[10px] left-[30px] xl:left-[50px] z-10 w-[50px] xl:w-[100px]"
                src="../images/princess/red-gift.png"
                alt="red-gift"
              />
              <StaticImage
                className="!absolute bottom-[-30px] left-[-20px] w-[170px] xl:w-[280px]"
                src="../images/princess/coin.png"
                alt="coin"
              />
            </div>
          )}
          <p className="text-center text-[8px] md:text-[1.2rem] text-gray-400 mb-10 px-10">
            The following Terms and Conditions apply to this Promotion:{" "}
            <a
              href="https://quickhitslots.zendesk.com/hc/en-us/articles/29309072629271"
              target="_blank"
            >
              Carnival Cruise Giveaway Terms and Conditions
            </a>{" "}
            <br />
            All communications related to this Promotion are governed by
            SciPlay's
            <span>
              <a href={"https://www.sciplay.com/terms"} target="_blank">
                {" "}
                Terms of Service{" "}
              </a>
            </span>
            and{" "}
            <a
              href="https://www.sciplay.com/terms-of-service#!/privacy"
              target="_blank"
            >
              Privacy Policy
            </a>
            , which you also agree and accept where entering the Promotion.
            Carnival’s{" "}
            <a
              href="https://www.princess.com/en-int/legal/legal-privacy#pcl-privacy-notice"
              target="_blank"
            >
              Privacy Policy
            </a>{" "}
            also applies to this Promotion.
          </p>
        </div>
        {isPopupVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="relative bg-white p-12 pt-8 rounded-lg shadow-lg w-[90%] md:w-[80%] text-center">
              <button
                className="absolute top-4 right-4 text-white bg-gray-600 rounded-[100%] h-[20px] w-[20px]"
                onClick={togglePopup}
              >
                ✕
              </button>
              <p className="text-[16px] mb-8 text-gray-700">
                Don't know your Player ID? Open the game settings in the top
                right to find your Player ID in the top center of the pop-up
              </p>
              <div className="landscape:flex gap-4">
                <StaticImage
                  src="../images/princess/PlayerID1.png"
                  alt="Player ID Info"
                  className="w-full mb-4"
                />

                <StaticImage
                  src="../images/princess/PlayerID2.png"
                  alt="Player ID Info"
                  className="w-full mb-4"
                />
              </div>
              <button
                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded"
                onClick={togglePopup}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Princess;
